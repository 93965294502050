"use client"

import { useEffect } from "react"
import { redirect, useParams } from "next/navigation"
import { useAuth, useOrganizationList } from "@clerk/nextjs"

export function SyncActiveOrganization() {
  const { setActive, isLoaded, userMemberships } = useOrganizationList({userMemberships: true});

  // Get the organization ID from the session
  const { orgId } = useAuth();

  // Get the organization ID from the URL
  const { orgId: urlOrgId } = useParams() as { orgId: string };

  useEffect(() => {
    if (!isLoaded) return;

    // If the org ID in the URL is not valid, redirect to the homepage
    if (!orgId?.startsWith("org_")) {
      console.log('Organizations', userMemberships);
      if (userMemberships.count > 0) {
        void setActive({ organization: userMemberships.data[0].organization.id });
        return;
      }
    }
  }, [orgId, isLoaded, setActive, urlOrgId, userMemberships])

  return null;
}